<template>
    <div>
        <h2>รายงานมูลค่าของคงคลัง</h2>

        <v-card class="mt-4 px-6 pt-6"> 
          <v-container>
            <v-row>
              <v-col>
                <delay-autocomplete
                  outlined
                  hide-selected
                  cache-items
                  v-model="warehouse"
                  label="คลัง *"
                  placeholder="พิมพ์เพื่อค้นหาคลัง"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/warehouseList'"
                  dense
                  return-object                        
                  item-text="name"
                  item-value="id"                  
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>                    
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="brands"             
                  label="แบรนด์"
                  placeholder="เว้นว่างถ้าต้องการดูทุกแบรนด์"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/brandList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="brandAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="categories"             
                  label="หมวดหมู่"
                  placeholder="เว้นว่างถ้าต้องการดูทุกหมวดหมู่"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/categoryList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="categoryAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
              <v-col cols="12" md="4">
                <delay-autocomplete
                  outlined
                  hide-selected               
                  deletable-chips
                  multiple
                  chips 
                  cache-items
                  v-model="tags"             
                  label="แท็ก"
                  placeholder="เว้นว่างถ้าต้องการดูทุกแท็ก"
                  no-data-text="ไม่พบข้อมูล"
                  :url="'/report/tagList'"
                  dense
                  :lazySearch="true"
                  return-object
                  ref="tagAutocomplete"
                  item-text="name"
                  item-value="id"
                  clearable
                  hide-details="auto"
                ></delay-autocomplete>
              </v-col>
            </v-row>            
            <v-row>
              <v-col class="d-flex">
                <v-btn 
                @click="generateReport"
                class="ml-auto"
                color="primary"
                :disabled="!warehouse"
                >ดูรายงาน</v-btn>
              </v-col>
            </v-row>
          </v-container>                        
        </v-card>              

        <v-card class="mt-4">
          <div class="d-flex pa-4">            
            <v-btn  
            class="ml-2"   
            @click="exportExcel"            
            ><v-icon class="mr-2">{{ icons.mdiExportVariant }}</v-icon>ส่งออก</v-btn>            
          </div>
          <LocalDatatable
          :headers="localDatatable.headers"
          :items="tableData"          
          >
            <template          
              v-slot:item.quantity="{ item }"
            >   
              {{ item.quantity | formatNumber(0) }}
            </template>

            <template          
              v-slot:item.available="{ item }"
            >   
              {{ item.available | formatNumber(0) }}
            </template>

            <template          
              v-slot:item.cost="{ item }"
            >   
              {{ item.cost | formatNumber }}
            </template>

            <template          
              v-slot:item.price="{ item }"
            >   
              {{ item.price | formatNumber }}
            </template>

            <template          
              v-slot:item.cost_available="{ item }"
            >   
              {{ item.cost_available | formatNumber }}
            </template>

            <template          
              v-slot:item.price_available="{ item }"
            >   
              {{ item.price_available | formatNumber }}
            </template>

          </LocalDatatable>
        </v-card>
    </div>
</template>
<script>
import LocalDatatable from '@/components/LocalDatatable.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import { mdiExportVariant } from '@mdi/js'
import * as XLSX from 'xlsx'
export default {
  data() {
    return {
      warehouse: null,
      brands: null,
      categories: null,
      tags: null,
      tableData: [],
      icons: {
        mdiExportVariant,
      },
      localDatatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        search: null,
        headers: [
          {
            text: 'สินค้า',
            value: 'name',
          },
          {
            text: 'sku',
            value: 'sku',
          },
          {
            text: 'barCode',
            value: 'barCode',
          },
          {
            text: 'ในคลัง',
            value: 'quantity',
            width: 100,
          },
          {
            text: 'ขายได้',
            value: 'available',
            width: 100,
          },
          {
            text: 'ทุน',
            value: 'cost',
            width: 100,
          },
          {
            text: 'ราคาขาย',
            value: 'price',
            width: 100,
          },
          {
            text: 'ทุนจากขายได้',
            value: 'cost_available',
            width: 200,
          },
          {
            text: 'ราคาขายจากขายได้',
            value: 'price_available',
            width: 200,
          },
        ],
      },
    }
  },
  watch: {},
  methods: {
    exportExcel() {
      const headers = this.localDatatable.headers.map(header => header.value)
      const headersText = this.localDatatable.headers.map(header => header.text)

      const data = this.tableData.map(item => {
        return headers.map(header => {
          if (!!item[header]) return item[header]

          if (item[header] == 0) return 0

          return ''
        })
      })

      const arrayData = [headersText, ...data]

      const dataWS = XLSX.utils.aoa_to_sheet(arrayData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'export_stockvalue_by_warehouse_' + this.warehouse.name + '.xlsx')
    },
    dateToDBFormat(date) {
      return (
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        date.getDate().toString().padStart(2, '0')
      )
    },
    dateDBFormatToThaiStandardFormat(dateString) {
      const [year, month, date] = dateString.split('-')

      return date + '/' + month + '/' + year
    },
    async generateReport() {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)

      const filter = {}

      if (!!this.brands) filter.brands = this.brands.map(item => item.id)

      if (!!this.categories) filter.categories = this.categories.map(item => item.id)

      if (!!this.tags) filter.tags = this.tags.map(item => item.id)

      try {
        const response = await asyncGet('report/stockvalue/' + this.warehouse.id, filter)

        this.tableData = response
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
  },
  components: {
    LocalDatatable,
  },
}
</script>